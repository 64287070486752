import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer bg-slate-900 border-t border-white border-opacity-10 py-4 p-7 flex justify-center">
            <div className="container">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 md:col-span-6 flex pb-3 md:pb-0">
                        <div className="flex justify-center md:justify-start w-full">
                            <a className="text-white text-opacity-90 hover:text-orange-600 mr-5 text-[16px]" target='_blank' rel="noreferrer" href="https://twitter.com/izoogood">
                                <FontAwesomeIcon icon={faTwitter} className="cursor-pointer" />
                            </a>
                            <a className="text-white text-opacity-90 hover:text-orange-600 mr-5 text-[16px]" target='_blank' rel="noreferrer" href="https://github.com/iZooGooD">
                                <FontAwesomeIcon icon={faGithub} className="cursor-pointer" />
                            </a>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 text-center md:text-right">
                        <p className="m-0 text-white text-opacity-75">© 2024 copyright - designed and styled by Lakshman Chaudhary</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;