/* eslint-disable jsx-a11y/anchor-is-valid */
import Typewriter from 'typewriter-effect';
import { Link, Element } from 'react-scroll';

const HeroSection = () => {
  return (
    <Element name="herosection" className="element">
      <section className="hero-section__wrapper pt-[120px] pb-[80px] lg:pt-[180px] lg:pb-[100px] bg-orange-50 relative flex justify-center">
        <div className="container px-4 relative z-20">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6">
              <div className="text-center lg:text-start mb-[50px] lg:mb-0">
                <h6 className="text-black uppercase text-[14px] md:text-[16px] tracking-[2px] font-[600] mb-[20px] md:mb-[30px]">
                  👋, My name is Lakshman
                </h6>
                <h1 className="text-black font-[600] text-[40px] lg:text-[60px] xl:text-[70px] leading-[1] mb-[25px] md:mb-[35px] h-24 lg:h-32 md:h-18">
                  <Typewriter
                    options={{
                      strings: ["I'm a Software Developer"],
                      autoStart: true,
                      loop: true,
                      pauseFor: 20000
                    }}
                  />
                </h1>
                <p className="text-[16px] md:text-[20px] mb-[8px] md:mb-[16px]">
                  Based in Pune, Maharashtra.
                </p>
                <div className="pt-[10px]">
                  <Link
                    className="px-btn px-btn-theme"
                    to="aboutSection"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={700}
                  >Let's Start</Link>
                </div>
              </div>
            </div>
            <div className="lg:col-span-6">
              <div className="home-image text-center">
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default HeroSection;
