import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faReact, faNode, faEmber, faSquareJs, faJava, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faBug, faDiagramProject } from '@fortawesome/free-solid-svg-icons'
import { Element } from 'react-scroll';

const SkillsSection = () => {
    return (
        <Element name="skillssection" className="element">
            <section className="skills-section__wrapper p-7 flex justify-center">
                <div className="container">
                    <div className="section-heading">
                        <h3 className="m-0">
                            <span>My Skills</span>
                        </h3>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-12 gap-5 grid-cols-8">
                        <div className="col-span-4">
                            <div className="bg-6 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faSquareJs} className="cursor-pointer javascript-icon" />
                                </div>
                                <h6>Javascript</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-3 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faReact} className="cursor-pointer react-icon" />
                                </div>
                                <h6>React / React Native</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faDiagramProject} className="cursor-pointer text-gray-500" />
                                </div>
                                <h6>Object Oriented Programming</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-6 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faJava} className="cursor-pointer java-icon" />
                                </div>
                                <h6>Java</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-4 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faNode} className="cursor-pointer node-icon" />
                                </div>
                                <h6>Node JS</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="feature-box-02">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faHtml5} className="cursor-pointer html-icon" />
                                </div>
                                <h6>HTML and CSS</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-5 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEmber} className="cursor-pointer ember-icon" />
                                </div>
                                <h6>Ember JS</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-6 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faGithub} className="cursor-pointer" />
                                </div>
                                <h6>Github</h6>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <div className="bg-6 feature-box-02 ">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faBug} className="cursor-pointer" />
                                </div>
                                <h6>Debugging</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
};

export default SkillsSection;
