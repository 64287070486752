/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/images/izoogood.png"
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-scroll';
import { Element } from 'react-scroll';


const Header = () => {
    // Initialize state to keep track of the active link
    const [activeLink, setActiveLink] = useState("HOME");
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80);
        });
    }, []);

    const sectionToHeaderNamesMapping = {
        herosection: "HOME",
        contactform: "CONTACT",
        portfoliosection: "PORTFOLIO",
        skillssection: "SKILLS",
        aboutSection: "INTRO"
    }

    // Function to handle click events on links
    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const handleMobileMenuButton = () => {
        setIsMobileMenuOpen(isMobileMenuOpen ? false : true);
    }

    const handleSetActive = (to) => {
        setActiveLink(sectionToHeaderNamesMapping[to]);
    };

    return (
        <Element name="headersection" className="element">
            <header className={`main-header flex justify-center w-full ${scroll ? 'sleeky-navbar' : ''}`}>
                <div className="container flex items-center justify-between one-page-nav relative py-5 lg:py-3">
                    <div className="logo z-10 relative">
                        <img src={logo} height={100} width={200} alt="logo"></img>
                    </div>
                    <div className={`navbar-collapse z-50 ${isMobileMenuOpen ? "menu-open" : ""}`}>
                        <ul className="navbar md:mx-auto flex flex-col md:flex-row p-4 lg:p-0">
                            <li className="mx-3 relative navbar-menu-item py-4 lg:p-0 md:p-0">
                                <Link
                                    className={`cursor-pointer ${activeLink === "HOME" ? "active" : ""}`}
                                    activeClass="active"
                                    onClick={() => handleLinkClick("HOME")}
                                    to="herosection"
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={700}
                                    onSetActive={handleSetActive}
                                >
                                    HOME
                                </Link>
                            </li>
                            <li className="mx-3 relative navbar-menu-item py-4 lg:p-0 md:p-0">
                                <Link
                                    className={`cursor-pointer ${activeLink === "INTRO" ? "active" : ""}`}
                                    onClick={() => handleLinkClick("INTRO")}
                                    to="aboutSection"
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={700}
                                    activeClass="active"
                                    onSetActive={handleSetActive}
                                >INTRO</Link>
                            </li>
                            <li className="mx-3 relative navbar-menu-item py-4 lg:p-0 md:p-0">
                                <Link
                                    className={`cursor-pointer ${activeLink === "SKILLS" ? "active" : ""}`}
                                    onClick={() => handleLinkClick("SKILLS")}
                                    to="skillssection"
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={700}
                                    activeClass="active"
                                    onSetActive={handleSetActive}
                                >SKILLS</Link>
                            </li>
                            <li className="mx-3 relative navbar-menu-item py-4 lg:p-0 md:p-0">
                                <Link
                                    className={`cursor-pointer ${activeLink === "PORTFOLIO" ? "active" : ""}`}
                                    onClick={() => handleLinkClick("PORTFOLIO")}
                                    to="portfoliosection"
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={700}
                                    activeClass="active"
                                    onSetActive={handleSetActive}
                                >
                                    PORTFOLIO
                                </Link>
                            </li>
                            <li className="mx-3 relative navbar-menu-item py-4 lg:p-0 md:p-0">
                                <Link
                                    className={`cursor-pointer ${activeLink === "CONTACT" ? "active" : ""}`}
                                    onClick={() => handleLinkClick("CONTACT")}
                                    to="contactform"
                                    spy={true}
                                    smooth={true}
                                    offset={50}
                                    duration={700}
                                    activeClass="active"
                                    onSetActive={handleSetActive}
                                >CONTACT</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="lg:hidden block px-4 z-10 relative">
                        <FontAwesomeIcon
                            icon={isMobileMenuOpen ? faXmark : faBars}
                            size="xl"
                            className={`cursor-pointer ${isMobileMenuOpen ? 'rotate-icon rotate180' : 'rotate-icon'}`}
                            onClick={() => handleMobileMenuButton()}
                        />
                    </div>
                    <div className="contact-now__container hidden lg:flex">
                        <Link
                            className={`px-btn px-btn-theme cursor-pointer ${activeLink === "CONTACT" ? "active" : ""}`}
                            onClick={() => handleLinkClick("CONTACT")}
                            to="contactform"
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={700}
                            activeClass="active"
                            onSetActive={handleSetActive}
                        >CONTACT NOW</Link>
                    </div>
                </div>
            </header>
        </Element>
    );

}

export default Header;