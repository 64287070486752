import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Element } from 'react-scroll';
import { useState, useEffect } from 'react';


const ContactMe = () => {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (window.location.search.includes('success=true')) {
            setSuccess(true);
        }
    }, []);
    return (
        <Element name="contactform" className="element">
            <section data-scroll-data="4" id="contactus" className="contact-me-section__wrapper section contactus-section flex justify-center bg-slate-900 p-7">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 lg:col-span-6 ">
                            <div className="p-[25px] md:p-[35px] bg-white">
                                <h6 className="text-[32px] font-[600] text-black mb-[5px]">Get in touch</h6>
                                <p className="text-[18px] mb-[30px]">I would love to hear from you.</p>
                                {success && (
                                    <p style={{ color: "green" }}>Thanks for your message! </p>
                                )}
                                <form name="contact" data-netlify="true" id="contact-form" method="POST">
                                    <input type="hidden" name="form-name" value="contact" />
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-12 md:col-span-6">
                                            <div className="form-group"><label className="form-label">First name</label>
                                                <input name="Name" id="name" placeholder="Name *" className="form-control" type="text"></input>
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-6">
                                            <div className="form-group"><label className="form-label">Your Email</label>
                                                <input name="Email" id="email" placeholder="Email *" className="form-control" type="email"></input>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="form-group"><label className="form-label">Subject</label>
                                                <input name="Subject" id="subject" placeholder="Subject *" className="form-control" type="text"></input>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="form-group"><label className="form-label">Your message</label><textarea name="message" id="message" placeholder="Your message *" rows="4" className="form-control"></textarea></div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="send"><button className="px-btn px-btn-theme2" type="submit" value="Send"> Send Message</button></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6 flex">
                            <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                                <div className="pb-10">
                                </div>
                                <ul className="contact-infos">
                                    <li>
                                        <div className="icon bg-1"><FontAwesomeIcon icon={faGithub} className="cursor-pointer" /></div>
                                        <div className="col">
                                            <h5>Check my Github</h5>
                                            <p><a href='https://github.com/iZooGooD' rel="noreferrer" target='_blank'>github.com/iZooGooD</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon bg-2"><FontAwesomeIcon icon={faEnvelope} className="cursor-pointer" /></div>
                                        <div className="col">
                                            <h5>Mail</h5>
                                            <p>izoogood.pro@gmail.com</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
};

export default ContactMe;