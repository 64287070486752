/* eslint-disable jsx-a11y/anchor-is-valid */
import torscrapper_snap from "../assets/images/scrapper_project.jpg"
import staybooker_snap from "../assets/images/stay_booker.png"
import { Element } from 'react-scroll';

const ProjectsShowcase = () => {
   return (
      <Element name="portfoliosection" className="element">
         <div className="bg-slate-900 p-7">
         </div>
         <section className="projects-showcase-section__wrapper bg-orange-50 p-7 flex justify-center">
            <div className="container">
               <div className="grid section-heading">
                  <div className="lg:col-span-6 text-center mx-auto">
                     <h3><span>Latest Projects</span></h3>
                  </div>
               </div>
               <div className="lightbox-gallery portfolio-box">
                  <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                     <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                        <div className="portfolio-img"><img src={staybooker_snap} title="" alt="" className="rounded-lg border-4"></img><a className="gallery-link gallery-link-icon"><i className="fas fa-arrow-right"></i></a></div>
                     </div>
                     <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                        <div className="portfolio-text">
                           <h6><span>Full Stack</span></h6>
                           <h4>Staybooker: hotel booking platform</h4>
                           <p>Stay Booker Pro is a production-ready hotel booking website built with modern web technologies. It is designed to be a fully functional and responsive web application for hotel booking services.</p>
                           <p>Built using React, Tailwind CSS, MirageJS for mocking APIs, and Cypress for end-to-end testing.</p>
                           <p className="font-medium">Key features:</p>
                           <ul>
                              <li>- Skeleton Loading</li>
                              <li>- Comprehensive Test Coverage</li>
                              <li>- Utility first css</li>
                              <li>- Reusable components</li>
                              <li>- Server-side pagination</li>
                              <li>- JWT based authorization</li>
                              <li>- MVC based Express API</li>
                           </ul>
                           <div className="btn-bar">
                              <a className="px-btn px-btn-theme px_modal" rel="noopener noreferrer" href="https://github.com/iZooGooD/stay-booker-hotel-booking-react-frontend" target="_blank">
                                 View Project
                              </a>
                              <a className="px-btn px-btn-theme2 ml-4 px-btn-dark hover:bg-gray-400" href="https://staybooker.netlify.app/" rel="noopener noreferrer" target="_blank">VIEW WEBSITE</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                     <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                        <div className="portfolio-text">
                           <h6><span>Full Stack</span></h6>
                           <h4>Torscrapper: My Innovative Torrent Search Engine</h4>
                           <p>Torscrapper is my brainchild, a powerful web application built with Python, JavaScript, SQL, Django, HTML, and CSS. It serves as a dynamic torrent search engine, fetching results from renowned torrent sites. What sets it apart is its smart caching system, storing results on the server for faster access and maintaining a robust SQLite database to save and recall frequent searches.</p>
                           <div className="btn-bar">
                              <a className="px-btn px-btn-theme px_modal" rel="noopener noreferrer" href="https://github.com/iZooGooD/torrscrapper" target="_blank">
                                 View Project
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0 order-first md:order-none">
                        <div className="portfolio-img"><img src={torscrapper_snap} title="" alt="" className="rounded-lg border-4"></img><a className="gallery-link gallery-link-icon"><i className="fas fa-arrow-right"></i></a></div>
                     </div>

                  </div>
               </div>
            </div>
         </section>
      </Element>
   );
};

export default ProjectsShowcase;