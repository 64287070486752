/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/images/character1.png"
import { Element } from "react-scroll";

const AboutMe = () => {
    return (
        <Element name="aboutSection" className="element">
            <section className="section p-14 flex justify-center">
                <div className="container">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0 bg-orange-50 p-7 about-me-image_wrapper rounded-3xl"><img src={logo} width={400} alt="logo"></img></div>
                        <div className="lg:col-span-6 lg:pl-12">
                            <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">I'm a Full stack Developer with over 3 years of experience.</h3>
                            <p className="text-[16px] md:text-[18px]">Hello, I'm Lakshman Chaudhary, a seasoned Full Stack Developer with over 3 years of hands-on experience in crafting web applications. Wondering about the name 'izoogood'? Well, it's a playful nod to my commitment to excellence. I'm truly passionate about what I do, and I take pride in being exceptionally good at it.</p>
                            <br></br>
                            <p className="text-[16px] md:text-[18px]">My expertise extends across the Full Stack, encompassing technologies like JavaScript, Express, React, MySQL, NextJS, and Ember. I thrive on the challenges of debugging and problem-solving. Additionally, I possess strong object-oriented programming skills and adhere to principles of OOP (Object-Oriented Programming) in my development process. Furthermore, I am highly adaptive to new technologies and enjoy creating projects with new tools and frameworks.</p>
                            <div className="grid grid-cols-12 pt-5">
                                <div className="col-span-6 sm:col-span-4">
                                    <h5 className="text-[26px] text-orange-600 font-[600]">10+</h5>
                                    <span>Projet Completed</span>
                                </div>
                            </div>
                            <div className="pt-6"><a className="px-btn px-btn-theme mr-4" href="#portfoliosection">Contact Me</a><a className="px-btn px-btn-dark">Portfolio</a></div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    );
}

export default AboutMe;