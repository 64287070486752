import './App.scss';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutMe from './components/AboutMe';
import SkillsSection from './components/SkillsSection';
import ProjectsShowcase from './components/ProjectsShowcase';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App overflow-hidden">
      <Header></Header>
      <main className='wrapper'>
        <HeroSection></HeroSection>
        <AboutMe></AboutMe>
        <SkillsSection></SkillsSection>
        <ProjectsShowcase></ProjectsShowcase>
        <ContactMe></ContactMe>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default App;
